.c-radios {
  position: relative;

  &__title {
    color: $color-default-20;
  }

  &__item {
    display: inline-block;
    position: relative;

    & + .c-radios__item {
      margin-left: $space-30;
    }

    & input {
      position: absolute;
      opacity: 0;
    }
  }

  &__label {
    @include icon(after, check);
    color: $color-default-20;
    font-weight: 600;
    padding-left: 2rem;
    
    &:before,
    &:after {
      transition: background .2s, color .2s, transform .2s;
    }

    &:before {
      background: $color-bg-base;
      border-radius: $border-radius;
      content: '';
      display: inline-block;
      height: 1.5rem;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5rem;
    }
    
    &:after {
      color: white;
      font-size: 0.875rem;
      left: 0.75rem;
      position: absolute;
      transform: translate(-50%, -50%) scale(0);
      top: 50%;
    }

    &--false {
      @include icon(after, close);
    }
    
    input:checked + & {
      color: $color-default-base;

      &:before {
        background: $color-link;
      }

      &--true:before {
        background: $color-secondary-base;
      }

      &--false:before {
        background: $color-tertiary-base;
      }
      
      &:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}

.c-radios--fieldset {
  line-height: 1.25;
  padding: $space-40 $space-10 $space-20;
  
  @media #{$break-min-20} {
    padding: 0.75rem 0.75rem 0.75rem 12rem;
  }
  
  & .c-radios__title {
    left: $space-10;
    position: absolute;
    top: 0.8125rem;
    
    @media #{$break-min-20} {
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.c-radios--truth {
  & .c-radios__label {
    &:after {
      color: $color-default-20;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  & input:checked + .c-radios__label:after {
    color: white;
  }
}
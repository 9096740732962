.o-footer {
  background: $color-default-base;
  color: white;
  padding: $space-20 0;
  margin-top: auto;

  &__copy {
    font-size: 0.875rem;
    font-weight: 500;
    
    &-light {
      opacity: 0.4;
    }
  }
}
.c-page-banner {
  align-items: center;
  color: white;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0.75rem 0;
  position: absolute;
  
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: $width-40;
  z-index: 100;

  &:before {
    bottom: 0;
    background: $color-default-40;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }

  &__action {
    margin: 0 $space-10;
  }

  &__cancel {
    color: $color-default-20;
    
    &:hover {
      color: $color-default-20;
    }
  }

  &--bottom {
    bottom: 0;
  }

  &--is-stuck {
    bottom: 0;
    position: fixed;
  }
}
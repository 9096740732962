/* Margin spacing top */
.u-spacing-mt--collapse {
  margin-top: 0;
}
.u-spacing-mt--tiny {
  margin-top: var(--space-10);
}
.u-spacing-mt--small {
  margin-top: var(--space-20);
}
.u-spacing-mt--medium {
  margin-top: var(--space-30);
}
.u-spacing-mt--large {
  margin-top: var(--space-40);
}
.u-spacing-mt--huge {
  margin-top: var(--space-50);
}

/* Margin spacing bottom */
.u-spacing-mb--collapse {
  margin-bottom: 0;
}
.u-spacing-mb--tiny {
  margin-bottom: var(--space-10);
}
.u-spacing-mb--small {
  margin-bottom: var(--space-20);
}
.u-spacing-mb--medium {
  margin-bottom: var(--space-30);
}
.u-spacing-mb--large {
  margin-bottom: var(--space-40);
}
.u-spacing-mb--huge {
  margin-bottom: var(--space-50);
}

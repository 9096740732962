.c-list {
  margin: $space-30 0 $space-50;

  &__head {
    position: relative;
    display: flex;
    flex-flow: row nowrap;

    &:before {
      bottom: -2px;
      background: $color-border-base;
      content: '';
      height: 2px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 100vw;
    }
  }

  &__body {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  &__row {
    align-items: center;
    border-bottom: 1px solid $color-border-base;
    display: flex;
    flex-flow: row nowrap;
  }

  &__cols {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    width: calc(100% - 2.5rem);
  }

  &__actions {
    flex: 0 0 auto;
    width: 2.5rem;
  }

  &__col {
    flex: 1 1 auto;
    font-size: 0.875rem;
    line-height: 1.2;
    padding: 1.375rem 0.3125rem;
    
    @media #{$break-min-30} {
      flex: 0 0 auto;
    }

    &--head {
      color: $color-default-20;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 1rem 0.3125rem;
      position: relative;
    }

    &--title {
      font-weight: 600;

      & a {
        color: $color-default-base;
        text-decoration: none;

        &:hover {
          color: $color-link;
          text-decoration: underline;
        }
      }
    }

    @for $i from 1 through 100 {
      &--#{$i} {
        width: ($i * 1%);
      }
    }
  }
}
.c-actions {
  position: relative;

  &__button {
    background: transparent;
    border-radius: $border-radius;
    border: none;
    color: $color-default-20;
    height: 2.3125rem;
    padding: 0;
    transition: color .2s, background .2s;
    width: 2.3125rem;

    &:hover {
      background: transparentize($color-link, 0.95);
      color: $color-link;
    }

    &:focus {
      outline: none;
      position: relative;

      &:after {
        background: $color-link;
        border-radius: 5px;
        bottom: -3px;
        content: '';
        display: block;
        left: -3px;
        opacity: 0.3;
        position: absolute;
        right: -3px;
        top: -3px;
        z-index: -1;
      }
    }

    .c-actions--is-active & {
      background: $color-link;
      color: white;
    }
  }

  &__flyout {
    background: white;
    border: 1px solid $color-border-30;
    border-radius: $border-radius;
    bottom: -0.5rem;
    box-shadow: 0 4px 8px 0 rgba(14,21,57,0.08);
    opacity: 0;
    overflow: hidden;
    padding: 0.75rem 0;
    position: absolute;
    right: 0;
    transform: translateY(95%);
    transition: visibility .5s, opacity .5s, transform .3s;
    visibility: hidden;
    width: 11rem;
    will-change: scroll-position, transform, visibility, opacity;
    z-index: 99;
    
    .c-actions--is-active & {
      opacity: 1;
      transform: translateY(100%);
      visibility: visible;
    }
  }
  
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: block;
    color: $color-default-base;

    & > a,
    & > button,
    & input[type=submit] {
      background: transparent;
      border: none;
      color: currentColor;
      cursor: pointer;
      display: block;
      font-family: inherit;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.2;
      padding: 0.4375rem 1.4375rem;
      text-align: left;
      text-decoration: none;
      width: 100%;

      &:hover {
        background: $color-bg-30;
        color: $color-link;
        text-decoration: underline;
      }
    }

    &--danger {
      color: $color-danger;
    }
  }
}
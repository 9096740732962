.select2 {
  
  &-dropdown {
    background: white;
    border: 1px solid $color-border-30;
    border-top: 1px solid $color-border-30!important;
    border-top-right-radius: $border-radius!important;
    border-top-left-radius: $border-radius!important;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px 0 rgba(14,21,57,0.08);
    padding: 0.75rem 0;
    margin-top: -0.125rem;

  }
  
  /* &-search,
  &-results {
    flex: 0 0 auto;
    max-width: 24.25rem;
    width: 100%;
  } */

  &-results {
    &__option {
      background: transparent;
      border: none;
      color: currentColor;
      cursor: pointer;
      display: block;
      font-family: inherit;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.2;
      padding: 0.4375rem 1.4375rem;
      text-align: left;
      text-decoration: none;
      width: 100%;

      &:hover {
        background: $color-bg-30;
        color: $color-link;
        text-decoration: underline;
      }
      
      &--highlighted {
        background-color: white !important;
        color: $color-link !important;
        
        &:hover {
          background-color: $color-bg-30 !important;
        }
      }
    }
  }

  &-search {
    padding: 0 0.75rem;

    &__field {
      border-radius: $border-radius;
      border-color: $color-border-base!important;
      padding-left: 0.6875rem !important;
      padding-right: 0.6875rem !important;
    }
  }
}

.c-pagination {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  padding: $space-20 0;
  position: relative;

  &:before {
    background: $color-bg-base;
    bottom: 0;
    content: '';
    position: absolute;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }

  &__list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
  }
  
  &__item {
    display: inline-block;
    margin: 0 0.3125rem;
  }
  
  &__link {
    background: white;
    border-radius: $border-radius;
    color: $color-default-20;
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: background .2s;
    width: 2.5rem;

    &--is-active {
      background: $color-tertiary-base;
      color: white;
    }
  }
  
  &__divider {}

  &__button {
    background: $color-tertiary-base;
    border: none;
    border-radius: $border-radius;
    color: white;
    display: block;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: none;
    text-align: center;
    transition: background .2s;
    width: 2.5rem;

    &--is-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.c-pagination--concise {
  padding: 0;

  &:before {
    display: none;
  }

  & .c-pagination__progress {
    color: $color-default-20;
    font-size: 0.9375rem;
    flex: 0 0 auto;
    margin: 0 0.3125rem;
  }

  & .c-pagination__button {
    background: $color-bg-base;
    color: $color-default-20;
    flex: 0 0 auto;
    margin: 0 0.3125rem;

    &:hover {
      background: darken($color-bg-base, 4%);
    }
  }
}
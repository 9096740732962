.c-search {
  position: relative;
  width: 100%;

  @media #{$break-min-20} {
    max-width: 15rem;
  }

  &__label {}

  &__input {
    border: 1px solid $color-border-base;
    border-radius: $border-radius;
    display: block;
    font-size: 0.9375rem;
    height: 2.5rem;
    max-width: 100%;
    padding: 0 1.25rem;
    width: 100%;
  }

  &__submit {
    background: transparent;
    border: none;
    color: $color-default-20;
    height: 2.5rem;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
  }
}
.c-no-results {
  @include icon(before, search);

  background: $color-bg-30;
  border-radius: $border-radius;
  color: $color-default-20;
  font-weight: 600;
  margin: $space-20 0;
  padding: $space-30;
  text-align: center;

  &:before {
    display: block;
    font-size: 2rem;
    margin: 0 auto $space-20;
  }
}
/* Cookie bar */
.c-cookiebar {
  background-color: $color-primary-base;
  color: white;
  line-height: 1.4;
  padding: $space-default 0;
  position: fixed;
  transition: transform .4s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  width: 100%;
  z-index: 999;

  &.is-visible {
    transform: translateY(0%);
  }

  & p {
    margin: 0;
  }

  & a {
    color: white;
  }

  &--top-bar {
    left: 0;
    top: 0;
    transform: translateY(-100%);
  }

  &--bottom-bar {
    left: 0;
    bottom: 0;
    transform: translateY(100%);
  }

  &--top-left-block {
    border-radius: $border-radius;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    left: $space-default;
    max-width: 300px;
    top: $space-default;
    transform: translateY(-100%) translateY(calc(#{$space-default} * -1));
  }

  &--top-right-block {
    border-radius: $border-radius;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    max-width: 300px;
    top: $space-default;
    transform: translateY(-100%) translateY(calc(#{$space-default} * -1));
    right: $space-default;
  }

  &--bottom-left-block {
    border-radius: $border-radius;
    bottom: $space-default;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    left: $space-default;
    max-width: 300px;
    transform: translateY(100%) translateY($space-default);
  }

  &--bottom-right-block {
    border-radius: $border-radius;
    bottom: $space-default;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    max-width: 300px;
    right: $space-default;
    transform: translateY(100%) translateY($space-default);
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: calc(#{$gutter-20} * -1);

    .c-cookiebar[class*="-bar"] & {
      align-items: center;

      @media #{$break-min-30} {
        margin: calc(#{$gutter-default} * -1);
      }
    }
  }

  &__info {
    flex: 1 1 0px;
    max-width: 100%;
    min-width: 75%;
    padding: $gutter-20;

    .c-cookiebar[class*="-bar"] & {
      @media #{$break-min-30} {
        padding: $gutter-default;
      }
    }
  }

  &__actions {
    flex: 0 0 auto;
    max-width: 100%;
    padding: $gutter-20;

    .c-cookiebar[class*="-bar"] & {
      @media #{$break-min-30} {
        padding: $gutter-default;
      }
    }
  }

  &__button {
    @include button(white, white, $color-primary-base, transparent, white, white);
  }
}

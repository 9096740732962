.c-fieldset {
  position: relative;

  & + .c-fieldset:not(.c-fieldset--section) {
    margin-top: $space-30;
  }

  &__title {
    margin: 0 $space-10 $space-20;

    @media #{$break-min-20} {
      margin: 0 1.25rem $space-20;
    }

    &--select {
      margin: 0 0 0.8125rem;
    }
  }

  &__sub-title {
    margin: $space-20 1.25rem;
  }

  &__list {
    border-bottom: 2px solid $color-border-base;
    border-top: 2px solid $color-border-base;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    border-bottom: 1px solid $color-border-base;
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    &:before {
      background: $color-bg-base;
      border-radius: $border-radius;
      bottom: -2px;
      content: '';
      left: -1px;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      right: -1px;
      top: -2px;
      transition: opacity .3s;
      visibility: hidden;
      will-change: opacity, visibility;
      z-index: 10;

      .c-fieldset--grey &,
      .c-section--grey & {
        background: white;
      }
    }

    & * {
      z-index: 20;
      will-change: z-index;
    }

    &:hover,
    &--is-active {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__label {
    color: $color-default-20;
    flex: 0 0 auto;
    left: $space-10;
    position: absolute;
    top: 0.8125rem;
    
    @media #{$break-min-20} {
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__input {
    appearance: none;
    background: transparent;
    border: none;
    color: $color-default-base;
    flex: 0 0 auto;
    font-weight: 700;
    position: relative;
    padding: $space-30 $space-10 $space-20;
    
    @media #{$break-min-20} {
      height: 2.875rem;
      margin: 0 0 0 12rem;
      padding: 0;
      width: calc(100% - 12rem);
    }
  }
  
  textarea.c-fieldset__input {
    display: block;
    padding: $space-30 $space-10 $space-20;
    width: 100%;
    
    @media #{$break-min-20} {
      padding: 0.75rem 0.75rem 0.75rem 0;
      width: calc(100% - 12rem);
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin: $space-30 0.625rem 0;

    &--head {
      margin: 0;
      position: absolute;
      right: 0;
      top: -2px;

      .c-fieldset--grey & {
        top: calc(#{$space-50} - 2px);
      }
    }
  }

  &__action {
    flex: 0 0 auto;

    &--push-left {
      margin-left: auto;
    }

    & + .c-fieldset__action {
      margin-left: $space-10;
    }
  }

  &__delete {
    background: transparent;
    border: none;
    color: $color-tertiary-base;
    font-weight: 500;
    font-size: $font-size-20;
    padding: 0;
    line-height: 1;
    text-decoration: underline;
  }
}


.c-fieldset--section {
  margin: $space-50 0;
}

.c-fieldset--subsection {
  margin: $space-50 0 $space-30;

  & + .c-fieldset--subsection {
    margin-top: $space-30;
  }

  & + .c-fieldset--section,
  & + .c-section {
    margin-top: $space-50;
  }
}

.c-fieldset--grey {
  margin: 0;
  padding: $space-50 0;
  position: relative;

  &:before {
    background: $color-bg-base;
    bottom: 0;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }
}

.c-fieldset--selects {
  & .c-fieldset__list {
    border: none;
  }

  & .c-fieldset__item {
    border: none;

    &:before {
      display: none;
    }

    & + .c-fieldset__item {
      margin-top: $space-30;
    }
  }
}

.c-fieldset--files {
  & .c-fieldset__item {
    line-height: 1.25;

    &:before {}
    
    &:nth-child(n + 6) {
      display: none;
    }
    
    & a {
      display: block;
      padding: 0.75rem 1.25rem;
      position: relative;
    }
  }
  
  & .c-fieldset__collapse {
    background: transparent;
    border: none;
    color: $color-link;
    font-weight: 700;
    padding: 0;
    margin: 1.25rem;
  }
  
  &.c-fieldset--is-open {
    & .c-fieldset__item {
      display: block!important;
    }

    & .c-fieldset__collapse {
      @include icon(before, angle-up);
      font-size: 0;

      &:before {
        font-size: 1rem;
      }
    }
  }
}


.c-fieldset--company {

  &:first-child:not(:last-child) {
  margin-bottom: 2.5rem;
  }
  
  &:not(:first-child) + .c-fieldset--company {
    margin-top: 2.75rem;
  }

  & .c-fieldset__actions:not(.c-fieldset__actions--head) {
    margin: .5rem 0 0;
    line-height: 1;
  }
}


.c-fieldset--narrow {
  & .c-fieldset__input {
    margin: 0 0 0 7.5rem;
    width: calc(100% - 7.5rem);
  }
}
/* Visually hidden */
.u-visually-hidden {
  @include visually-hidden;
}

/* Hide utilities */
.u-hide {
  display: none !important;
}

/* Hide @only */
.u-hide--at-20 {
  @media #{$break-only-20} {
    display: none !important;
  }
}
.u-hide--at-30 {
  @media #{$break-only-30} {
    display: none !important;
  }
}
.u-hide--at-40 {
  @media #{$break-only-40} {
    display: none !important;
  }
}
.u-hide--at-50 {
  @media #{$break-only-50} {
    display: none !important;
  }
}
.u-hide--at-60 {
  @media #{$break-min-60} {
    display: none !important;
  }
}

/* Hide @min */
.u-hide--at-min-20 {
  @media #{$break-min-20} {
    display: none !important;
  }
}
.u-hide--at-min-30 {
  @media #{$break-min-30} {
    display: none !important;
  }
}
.u-hide--at-min-40 {
  @media #{$break-min-40} {
    display: none !important;
  }
}
.u-hide--at-min-50 {
  @media #{$break-min-50} {
    display: none !important;
  }
}
.u-hide--at-min-60 {
  @media #{$break-min-60} {
    display: none !important;
  }
}

/* Hide @max */
.u-hide--at-max-20 {
  @media #{$break-max-20} {
    display: none !important;
  }
}
.u-hide--at-max-30 {
  @media #{$break-max-30} {
    display: none !important;
  }
}
.u-hide--at-max-40 {
  @media #{$break-max-40} {
    display: none !important;
  }
}
.u-hide--at-max-50 {
  @media #{$break-max-50} {
    display: none !important;
  }
}

/* Show utilities */
.u-show {
  display: block !important;
}

/* Show only */
.u-show--at-tiny {
  display: none;

  @media #{$break-only-20} {
    display: block !important;
  }
}
.u-show--at-small {
  display: none;

  @media #{$break-only-30} {
    display: block !important;
  }
}
.u-show--at-medium {
  display: none;

  @media #{$break-only-40} {
    display: block !important;
  }
}
.u-show--at-large {
  display: none;

  @media #{$break-only-50} {
    display: block !important;
  }
}
.u-show--at-huge {
  display: none;

  @media #{$break-min-60} {
    display: block !important;
  }
}

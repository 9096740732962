.c-status {
  border-radius: $border-radius;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
  padding: 0.1875rem 0.5rem;

  &--yellow,
  &--project-0,
  &--quotation-0 {
    background: $color-quaternary-base;
  }
  
  &--grey,
  &--project-1 {
    background: $color-bg-base;
  }
  
  &--green,
  &--project-2,
  &--quotation-3 {
    background: $color-secondary-base;
    color: white;
  }
  
  &--red,
  &--quotation-2 {
    background: $color-tertiary-base;
    color: white;
  }
  
  &--blue,
  &--quotation-1 {
    background: $color-primary-base;
    color: white;
  }
}
/* Fonts */
@font-face {
  font-family: 'icons';
  src: url('/dist/fonts/icons/icons.eot');
  src: url('/dist/fonts/icons/icons.eot#iefix') format('embedded-opentype'),
       url('/dist/fonts/icons/icons.woff2') format('woff2'),
       url('/dist/fonts/icons/icons.woff') format('woff'),
       url('/dist/fonts/icons/icons.ttf') format('truetype'),
       url('/dist/fonts/icons/icons.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Icon */
.c-icon {
  display: inline-block;

  &--direction-top {
    transform: rotate(270deg);
  }

  &--direction-bottom {
    transform: rotate(90deg);
  }

  &--direction-left {
    transform: rotate(180deg);
  }

  &--direction-right {
    transform: rotate(0deg);
  }

  &--large {
    height: 24px;
    width: 24px;
  }

  &--angle-down { @include icon(before, angle-down); }
  &--angle-left { @include icon(before, angle-left); }
  &--angle-right { @include icon(before, angle-right); }
  &--angle-up { @include icon(before, angle-up); }
  &--check { @include icon(before, check); }
  &--city { @include icon(before, city); }
  &--dots { @include icon(before, dots); }
  &--folder { @include icon(before, folder); }
  &--search { @include icon(before, search); }
  &--user { @include icon(before, user); }
  &--caret-down { @include icon(before, caret-down); }
  &--caret-left { @include icon(before, caret-left); }
  &--caret-right { @include icon(before, caret-right); }
  &--caret-up { @include icon(before, caret-up); }
  &--plus { @include icon(before, plus); }
  &--close { @include icon(before, close); }
}

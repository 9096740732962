.c-login {
  display: flex;
  flex-flow: row nowrap;
  min-height: 100vh;
  padding: $space-50 0;
  position: relative;

  &:before {
    background: $color-default-base;
    bottom: 0;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }

  &__inner {
    background: white;
    flex: 0 0 auto;
    margin: auto;
    max-width: 26.25rem;
    text-align: center;
    width: 100%;
  }

  &__header {
    background: #212950;
    border-bottom: 3px solid $color-quaternary-base;
    padding-top: 2.75rem;
  }

  &__logo {
    max-width: 12.5rem;
    margin: 0 auto -10px;
    width: 100%;
  }

  &__box-content {
    padding: 3.5rem 3.125rem;
  }

  &__title {
    font-weight: 800;
    line-height: 1.1;
    margin: 0 0 0.375rem;
  }

  &__intro {
    color: $color-default-20;
    font-weight: 500;
    line-height: 1.25;
    margin: 0;
  }

  &__submit {
    @include button();

    background: $color-default-base;
    border: none;
    font-size: 1.0625rem;
    font-weight: 800;
    line-height: 1.25;
    margin-top: $space-30;
    padding: 0.75rem;
    width: 100%;
  }

  &__form {
    margin: $space-30 0 0;
  }

  &__form-element {
    margin: $space-30 0 0;

    &--checkbox {
      margin: 1.25rem 0 0;
      text-align: left;
    }
  }

  &__label {
    color: $color-default-base;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
  }

  &__input {
    appearance: none;
    border: none;
    border-bottom: 2px solid $color-border-30;
    color: $color-default-base;
    font-size: 1.0625rem;
    font-weight: 500;
    height: 2.5rem;
    text-align: center;
    width: 100%;

    &:focus {
      border-color: $color-default-base !important;
    }

    &::placeholder {
      color: #9E9F9E;
    }
  }
}
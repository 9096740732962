.c-modal {
  align-items: center;
  background: rgba($color-default-base, 0.8);
  bottom: 0;
  display: flex;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 999;
  transition: visibility .5s, opacity .5s;
  
  &__inner {
    background: white;
    border-radius: $border-radius;
    padding: $space-30;
    flex: 0 0 auto;
    margin: auto;
    transform: translateY(2rem);
    transition: transform .5s;
    width: 25rem;
  }

  &__icon {
    font-size: 2rem;
    line-height: 1;
    margin: 0 0 1.375rem;
  }

  &__title {
    color: currentColor;
    font-size: $font-size-40;
    margin: $space-20 0 0.3215rem;
  }
  
  &__text {
    color: $color-default-base;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0.3215rem 0 $space-30;
  }

  &__form {
    margin: $space-30 0;
  }
  
  &__actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin: $space-30 0 0;
    width: 100%;
  }

  &__action {
    flex: 0 0 auto;

    & + .c-modal__action {
      margin-left: $space-10;
    }

    .c-modal__actions--fw & {
      width: 100%;
      margin-left: 0;
      margin-top: $space-10;
    }
  }

  &__close {
    background: $color-bg-base;
    border: none;
    border-radius: 100%;
    color: $color-default-base;
    font-size: 1.5rem;
    height: 1.875rem;
    line-height: 1.875rem;
    outline: none;
    position: absolute;
    right: $space-20;
    top: $space-20;
    text-align: center;
    width: 1.875rem;

    &:hover {
      background: darken($color-bg-base, 10%);
    }
  }

  &--primary {
    color: $color-primary-base;
  }

  &--secondary {
    color: $color-secondary-base;
  }

  &--tertiary {
    color: $color-tertiary-base;
  }

  &--is-open {
    opacity: 1;
    visibility: visible;

    & .c-modal__inner {
      transform: translateY(0);
    }
  }
}
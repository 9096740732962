.c-section {
  margin: $space-50 0;

  & .c-fieldset--subsection {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-section--grey {
  margin: 0;
  padding: $space-50 0;
  position: relative;

  &:before {
    background: $color-bg-base;
    bottom: 0;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }
}
.c-sortable-link {
  color: $color-default-20;
  display: inline-block;
  padding-left: 0.625rem;
  position: relative;
  text-decoration: none;

  &:hover {
    color: $color-default-base;
  }

  &__icon {
    @include icon(before, caret-up);
    @include icon(after, caret-down);

    font-size: 0.625rem;
    height: 0.75rem;
    left: 0.25rem;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5rem;

    &:before,
    &:after {
      display: inline;
      left: 0;
      line-height: 1;
      position: absolute;
      transform: translateY(-1px);
    }

    &:before {
      top: -0.125rem;
    }
    
    &:after {
      bottom: -0.125rem;
    }

    &--asc {
      &:after {
        color: $color-default-base;
      }
    }

    &--desc {
      &:before {
        color: $color-default-base;
      }
    }
  }

  &--is-sorting {
    color: $color-default-base;
  }
}
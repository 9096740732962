/* Text align */
.u-text-align--left {
  text-align: left;
}
.u-text-align--right {
  text-align: right;
}
.u-text-align--center {
  text-align: center;
}

/* Text color */
.u-text-color--default {
  color: var(--color-default-base);
}
.u-text-color--primary {
  color: var(--color-primary-base);
}

/* Text weight */
.u-text-weight--bold {
  font-weight: var(--font-weight-60);
}

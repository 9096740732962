.c-select {
  position: relative;

  &__control {
    @include icon(after, angle-down);
    position: relative;

    &:after {
      border-left: 1px solid $color-border-base;
      font-size: 0.75rem;
      height: 1.25rem;
      line-height: 1.25rem;
      // pointer-events: none;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 2.75rem;
    }
    
    & select {
      appearance: none;
      background: white;
      border: 1px solid $color-border-base;
      border-radius: $border-radius;
      color: $color-default-20;
      cursor: pointer;
      display: block;
      font-size: 0.9375rem;
      font-weight: 600;
      height: 2.5rem;
      max-width: 100%;
      padding: 0 1.25rem;
      width: 100%;
    }
  }

  &__icon {
    color: $color-default-20;
    font-size: 0.75rem;
    pointer-events: none;
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      background: $color-border-base;
      content: '';
      height: 10px;
      left: -0.625rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }
  }
}

.c-select--header {
  & .c-select__control select {
    padding-right: 4rem;
  }
}

.c-select--company {
  & .c-select__label {
    display: block;
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.22;
    margin: 0.6875rem 1.25rem 0.75rem;
  }
}

.c-select--fieldset {
  & .c-select__label {
    color: $color-default-20;
    flex: 0 0 auto;
    left: $space-10;
    position: absolute;
    top: 0.8125rem;
    z-index: 10;
    
    @media #{$break-min-20} {
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  
  & .c-select__control select {
    appearance: none;
    background: transparent;
    border: none;
    color: $color-default-base;
    flex: 0 0 auto;
    font-size: $font-size-30;
    font-weight: 700;
    height: auto;
    padding: $space-30 $space-10 $space-20;
    
    @media #{$break-min-20} {
      height: 2.875rem;
      margin: 0 0 0 12rem;
      padding: 0;
      width: calc(100% - 12rem);
    }
  }
}

.c-select--narrow {
  & .c-select__control select {
    @media #{$break-min-20} {
      margin: 0 0 0 8.375rem;
      width: calc(100% - 8.375rem);
    }
  }
}

.c-select--label {
  &:after {
    top: 0.625rem;
  }

  & .c-select__control select {
    border: none;
    color: initial;
    font-size: 1.125rem;
    font-weight: 800;
    padding: 0 1.25rem 0.75rem;
    height: auto;
    width: 100%;
  }
}

.c-select--select2 {
  & .c-select__control .select2 {
    display: block;
    width: 100% !important;

    &-selection {
      background: $color-bg-base;
      border: none;
      border-radius: $border-radius;
      
      &--single {
        height: auto;
      }

      &__arrow {
        display: none;
      }
      
      &__rendered {
        color: inherit !important;
        font-size: 1rem;
        font-weight: 700;
        height: 100% !important;
        line-height: 1.25 !important;
        min-height: 2.875rem;
        padding: 1rem 2.75rem 1rem 1.25rem !important;
      }
    }
  }

  &.c-select--fieldset {
    & .c-select__control .select2 {
      margin: 0 !important;
      width: 100% !important;
      
      @media #{$break-min-20} {
        margin-left: 12rem !important;
        width: calc(100% - 12rem)!important;
      }

      &-selection {
        background: transparent;
        
        &__rendered {
          color: inherit !important;
          font-size: 1rem;
          font-weight: 700;
          height: 100% !important;
          line-height: 1.25 !important;
          min-height: 2.875rem;
          padding: $space-30 $space-10 $space-20 !important;

          @media #{$break-min-20} {
            padding: 0.75rem 2.75rem 0.75rem 0 !important;
          }
        }
      }
    }

    &.c-select--narrow {
      & .c-select__control .select2 {
        @media #{$break-min-20} {
          margin: 0 0 0 8.375rem !important;
          width: calc(100% - 8.375rem) !important;
        }
      }
    }
  }
}


.c-select--title {
  & .c-select__label {
    color: $color-default-20;
    display: block;
    margin: 0 1.25rem 0.25rem;
  }
  
  & .c-select__control {

    & select {
      background: transparent;
      border: none;
      color: $color-default-base;
      font-size: $font-size-40;
      font-weight: 800;
      height: auto;
      padding: 0 1.25rem;
    }
  }
}
/* System tabs */
.c-system-tabs {
  margin: 0;

  &__list {
    display: flex;
    list-style: none;
    margin: calc((#{$space-10} / 2) * -1);
    padding: 0;

    &--secondary {
      margin: $space-10 calc((#{$space-20} / 2) * -1);
    }
  }

  &__item {
    margin: 0;
    padding: calc(#{$space-10} / 2);

    .c-system-tabs__list--secondary & {
      padding: 0 calc(#{$space-20} / 2);
    }

    & a {
      background-color: white;
      border: 1px solid $color-default-10;
      border-radius: $border-radius;
      color: $color-primary-base;
      display: block;
      font-size: $font-size-20;
      font-weight: $font-weight-60;
      line-height: $line-height-10;
      padding: $space-20;
      text-decoration: none;

      .c-system-tabs__item--is-active &,
      &:hover {
        background-color: $color-primary-base;
        border-color: $color-primary-base;
        color: white;
      }

      .c-system-tabs__list--secondary & {
        font-size: $font-size-10;
        padding: calc(#{$space-20} / 2) 0;
        position: relative;

        &::after {
          border-top: 1px dotted currentColor;
          bottom: 0;
          content: '';
          display: block;
          height: 0;
          left: 0;
          position: absolute;
          width: 100%;
        }

        &:hover {
          color: $color-primary-base;
        }
      }
    }
  }
}

/* Background color */
.u-bg--primary {
  background-color: var(--color-primary-base);
}
.u-bg--light {
  background-color: var(--color-default-10);
}

/* Background image fit */
.u-bg-fit {
  background-position: center center;
  display: block;
  height: 100%;
  width: 100%;

  & img {
    visibility: hidden;
  }
}
.u-bg-fit--cover {
  background-repeat: no-repeat;
  background-size: cover;
}
.u-bg-fit--contain {
  background-repeat: no-repeat;
  background-size: contain;
}

/* Float */
.u-float--left {
  float: left;
}
.u-float--right {
  float: right;
}

/* Shadow */
.u-shadow {
  box-shadow: 20px 20px 40px rgba(0, 0, 0, .25);
}

/* Clear fix */
.u-clearfix {
  @include clearfix;
}

/* No scroll */
.u-no-scroll {
  height: 100% !important;
  overflow: hidden !important;
}
